import { Component, h } from 'preact';
import { IntlProvider, Text } from 'preact-i18n';
import { Router, Route } from 'preact-router';
import classnames from 'classnames';

import defaultDefinition from '../i18n/fr.json';
import { TransactionVerify, Transaction, TransactionFees } from '../models';
import { TransactionService } from '../services';
import {
    compileTheme, makeSureTransactionWasVerified, ReferrerService, Shared,
    processTransactionCancelation, isMobile, TransactionFeesSub
} from '../utils';
import MobileMoneyComponent from './mobile-money.component';
import NavComponent from './nav.component';
import TransactionStatusComponent from './transaction-status.component';
import TransactionVerifyComponent from './transaction-verify.component';
import { Subscription } from 'rxjs';
import CybersourceComponent from './cybersource.component';
import MobileToolbarComponent from './mobile-toolbar.component';

import TpeComponent from './tpe.component';
import ModalTitleComponent from './modal-title.component';
import MoreInfoComponent from './more-info-component';
import BmoComponent from './bmo.component';
import CorisMoneyComponent from './coris.component';

interface AppState {
    definition: any;
    showNav: boolean,
    style: string;
    language: string;
    isMobile: boolean;
    showMobileToolbarMenu: boolean;
}

export default class AppComponent extends Component<any, AppState> {
    transactionService = new TransactionService;
    referrer = new ReferrerService;
    transaction: Transaction;
    state: AppState = {
        definition: defaultDefinition,
        showNav: false,
        style: '',
        language: 'fr',
        isMobile: isMobile(),
        showMobileToolbarMenu: false
    };
    transactionFeesSub: TransactionFeesSub = {};

    subscriptions: Subscription[] = [];

    constructor () {
        super();
        this.referrer.saveReferer();
        this.updateDefinitionFile = this.updateDefinitionFile.bind(this);

        this.subscriptions.push(
            makeSureTransactionWasVerified((verifyTrans: TransactionVerify) => {
                if (!verifyTrans) {
                    return;
                }

                this.transaction = verifyTrans.transaction;

                this.setState({ style: this.getStyle(verifyTrans), showNav: true });

                if (this.transaction.custom_metadata?.custom_locale) {
                    this.updateDefinitionFile(this.transaction.custom_metadata?.custom_locale);
                }
            }, false),
            Shared.$paymentModeSub.subscribe((newMode) => {
                if (newMode) {
                    this.loadTransactionFees(newMode);
                }
            }),
        );
    }

    componentWillUnmount () {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    updateDefinitionFile (newLanguage: string) {
        if (!['en', 'fr'].includes(newLanguage)) {
            return;
        }

        Shared.lang = newLanguage;
        this.setState({ language: newLanguage });
        import(`../i18n/${newLanguage}.json`)
            .then(def => this.setState({ definition: def.default }));
    }

    onClose = () => {
        processTransactionCancelation();
    }

    handleRoute = async e => {
        this.setState({
            showMobileToolbarMenu: e.url !== '/menu' && e.url !== '/'
        });
    };

    loadTransactionFees(mode: string) {
        this.transactionFeesSub.loading = true;
        Shared.transactionFees.next(this.transactionFeesSub);

        this.transactionService.fees(Shared.token, mode)
        .then((data: TransactionFees) => {
            this.transactionFeesSub = {
                transactionFees: data,
                loading: false
            };
            Shared.transactionFees.next(this.transactionFeesSub);
        }).catch(() => {
            this.transactionFeesSub.loading = false;
            Shared.transactionFees.next(this.transactionFeesSub);
        });
    }

    render () {
        return (
            <IntlProvider definition={this.state.definition}>
                <div class="modal d-md-flex d-block align-items-center justify-content-center" role="dialog">
                    <style>{this.state.style}</style>
                    <div class="modal-dialog" role="document">
                        <MoreInfoComponent />

                        <div class={classnames('modal-content', {'show' : this.state.showNav})}>
                            <MobileToolbarComponent show={ this.state.showMobileToolbarMenu } />
                            <ModalTitleComponent show={ this.state.showNav } />
                            <a class={classnames('modal-close', { 'd-none': this.state.isMobile })}
                                role="button"
                                onClick={() => this.onClose()} />

                            <div class="row modal-content-child">
                                <div class={classnames("col-5 nav-container", { 'd-none': !this.state.showNav })}>
                                    <NavComponent onLanguageChanged={this.updateDefinitionFile} activeLang={ this.state.language } />
                                </div>
                                <div class={classnames("page-container", {'col-7': this.state.showNav, 'col-12': !this.state.showNav})}>
                                    <Router onChange={this.handleRoute}>
                                        <NavComponent path="/menu" onLanguageChanged={this.updateDefinitionFile} activeLang={ this.state.language } position='right'/>
                                        <Route path="/mobile-money" component={MobileMoneyComponent} lang={ this.state.language} />
                                        <Route path="/card" component={CybersourceComponent} lang={ this.state.language} />
                                        <Route path="/tpe" component={TpeComponent} />
                                        <Route path="/bmo" component={BmoComponent} />
                                        <Route path="/status/:status" component={TransactionStatusComponent} />
                                        <Route path="/:token?" component={TransactionVerifyComponent} />
                                        <Route path="/coris-money" component={CorisMoneyComponent} />
                                    </Router>
                                </div>
                            </div>
                            <div class="secured-by">
                                <a href="https://www.fedapay.com" target="_blank">
                                    <img src="https://cdn.fedapay.com/img/secured-by.svg" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </IntlProvider>
        );
    }

    private getStyle (verifyTrans: TransactionVerify): string {
        const theme = compileTheme(verifyTrans.getCheckoutTheme());

        return `
            body {
                --page-background-color: ${theme.page_background_color};
                --background-color: ${theme.background_color};
                --nav-background: ${theme.nav_background};
                --button-color: ${theme.button_color};
                --nav-text-active-color: ${theme.nav_text_active_color};
                --nav-subtext-active-color: ${theme.nav_subtext_active_color};
                --nav-background-active-color: ${theme.nav_background_active_color};
                --header-background-color: ${theme.header_background_color};

                --nav-text-color: ${theme.nav_text_color};
                --nav-border-color: ${theme.nav_border_color};
                --text-color: ${theme.text_color};
                --placeholder-color: ${theme.placeholder_color};
                --border-color: ${theme.border_color};
                --input-focus-color: ${theme.input_focus_color};
                --header-border-color: ${theme.header_border_color};
                --header-text-color: ${theme.header_text_color};
                --button-text-color: ${theme.button_text_color};
                --button-hover-color: ${theme.button_hover_color};
            }

            .page-container .custom-select {
                background-image: url("${theme.select_icon}")!important;
            }
        `;
    }
}
